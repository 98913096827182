<template>
	<div class="step4">
		<a-form-model ref="step" :model="step4">
			<a-card title="Inclusions" v-if="questions.inclusions && inclusions.length">
				<a-form-model-item prop="inclusions" label="Choose Inclusions">
					<a-checkbox-group v-model="step4.inclusions" :options="inclusions" />
				</a-form-model-item>
			</a-card>

			<a-card class="mt-3" v-if="transaction.type == 'purchase' && instance.productType === 'lowrise'">
				<a-tooltip overlayClassName="change-tooltip-color" slot="title">
					<template slot="title">
						<p class="mb-0">Lot Premiums: ${{ formatPrice(lotPremiums) }}<br />
							Model Price: ${{ formatPrice(pkgPrice) }}
							<span v-if="reservationAmount"><br />Unit Reservation Fee:
								-${{ formatPrice(reservationAmount) }}</span>
						</p>
					</template>
					<div style="display:inline-block">Suggested MSRP: ${{ msrp }}</div>
				</a-tooltip>

				<a-row :gutter="16">
					<a-col :span="6">
						<a-form-model-item label="Elevation Price ($)" class="mb-2">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(step4.package.price)" disabled/>
							<a-input-number v-else @change="calculateTotalPurchase" class="input-number"
								:formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" v-model="step4.package.price" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6" v-for="item in step4.premiums" :key="item.id">
						<a-form-model-item :label="`${item.name} ($)`" class="mb-2">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(item.price)" disabled/>
							<a-input-number v-else @change="calculateTotalPurchase" class="input-number"
								:formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" v-model="item.price"/>
						</a-form-model-item>
					</a-col>
				</a-row>
				<hr />
				<a-row :gutter="16">
					<a-col :span="6">
						<a-form-model-item v-if="reservationAmount" label="Reservation Fee">
							<a-input-number :disabled="true" class="input-number"
								:value="reservationAmount" :formatter="value => '$ ' + formatPrice(value)" />
						</a-form-model-item>
						<a-form-model-item class="imp-label" label="Purchase price (Offer)" prop="purchasePrice"
							:rules="req('Please input the Purchase price')">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(step4.purchasePrice)" disabled/>
							<a-input-number v-else class="input-number" v-model="step4.purchasePrice"
								:formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" @change="calculateDeposits" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-card>

			<div v-if="transaction.type === 'purchase' && instance.productType === 'highrise'">
				<a-card title="Add Ons" class="mt-3" v-if="addOnsList.length">
					<a-row :gutter="24">
						<a-col :span="$mq === 'lg' ? 8 : 24" v-for="(addOn, index) in addOnsList" :key="index">
							<a-row :gutter="16" v-if="$mq === 'lg'">
								<a-col :span="24">
									<h6>{{ addOn.name }}</h6>
									<a-divider />
								</a-col>
							</a-row>
							<div v-for="addOnIndex in [0, 1, 2]" :key="addOnIndex">
								<a-row :gutter="16" v-if="addOnsToShow[addOn.type] > addOnIndex"
									:class="(index || addOnIndex) && $mq !== 'lg' ? 'mt-3' : ''">
									<a-col :span="9">
										<p>{{ addOn.name }} #</p>
										<div v-if="!!addOns[addOn.type][addOnIndex].preDefined">
											<a-input :value="addOn.type + ' #' + addOns[addOn.type][addOnIndex].name"
												:disabled="!!addOns[addOn.type][addOnIndex].preDefined" />
										</div>
										<div v-else>
											<a-select :value="addOns[addOn.type][addOnIndex].id"
												@change="(e) => onAddOnChange(e, addOnIndex, addOn.type)" allowClear>
												<a-select-option v-for="(a, index) in generalAddOns[addOn.type]"
													:key="a.id + index" :value="a.id"
													:disabled="!!a.disabled">{{ addOn.name }} #{{ a.name
													}}</a-select-option>
											</a-select>
										</div>
									</a-col>
									<a-col :span="1" />
									<a-col :span="12">
										<p>{{ addOn.name }} Price($)</p>
										<a-input-number v-if="$p < 40" class="input-number"
											:value="'$ ' +formatPrice(addOns[addOn.type][addOnIndex].price)" disabled/>
										<a-input-number v-else @change="calculateTotalAddOnsPrice"
											class="input-number"
											:formatter="value => '$ ' + formatPrice(value)"
											:parser="value => value.replace(/\$\s?|(,*)/g, '')"
											v-model="addOns[addOn.type][addOnIndex].price" />
									</a-col>
								</a-row>
								<div v-if="addOnsToShow[addOn.type] > addOnIndex + 1 && $mq === 'lg'">
									<a-divider />
								</div>
							</div>
							<div v-if="index < 2 && $mq !== 'lg'">
								<a-divider />
							</div>
						</a-col>
					</a-row>
					<a-divider class="mt-5" />
					<a-row :gutter="16" class="mt-3">
						<a-col :span="$mq === 'lg' ? 3 : 10">
							<h6 class="mt-2">Add Ons MSRP </h6>
						</a-col>
						<a-col :span="$mq === 'lg' ? 4 : 10">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(step4.addOnsPrice)" disabled/>
							<a-input-number v-else class="input-number" @change="calculateTotalPurchase"
								v-model="step4.addOnsPrice" :formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" required />
						</a-col>
					</a-row>
				</a-card>

				<a-card class="mt-3">
					<a-form-model-item v-if="reservationAmount" label="Reservation Fee">
						<a-input-number :disabled="true" class="input-number" :value="reservationAmount"
							:formatter="value => '$ ' + formatPrice(value)" />
					</a-form-model-item>
					<a-tooltip overlayClassName="change-tooltip-color" slot="title">
						<template slot="title">
							<p class="mb-0">
								Unit Premiums: ${{ formatPrice(unitPremiums) }}<br />
								Unit AddOns: ${{ formatPrice(step4.addOnsPrice) }}<br />
								Unit Price: ${{ formatPrice(pkgPrice) }}
								<span v-if="reservationAmount"><br />Unit Reservation Fee:
									-${{ formatPrice(reservationAmount) }}</span>
							</p>
						</template>
						<div style="display:inline-block">Suggested Unit Price (MSRP): ${{ msrp }}</div>
					</a-tooltip>
					<a-row :gutter="16">
						<a-col :span="6">
							<a-form-model-item class="imp-label" label="Purchase price (Offer)" prop="purchasePrice"
								:rules="req('Please input the Purchase price')">
								<a-input-number v-if="$p < 40" class="input-number"
									:value="'$ ' +formatPrice(step4.purchasePrice)" disabled/>
								<a-input-number v-else class="input-number" v-model="step4.purchasePrice"
									:formatter="value => '$ ' + formatPrice(value)"
									:parser="value => value.replace(/\$\s?|(,*)/g, '')" @change="calculateDeposits" />
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-card>
			</div>

			<a-card class="mt-3" title="Deposit Structure"
				v-if="questions.deposits > 0 && transaction.type == 'purchase' && step4.deposits.length">
				<a-row :gutter="16">
					<a-col :span="2">
						<a-form-model-item :label="`#`" class="mb-0">
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq === 'lg' ? 6 : 10">
						<a-form-model-item :label="`Deposit Date`" class="mb-0">
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item :label="`Deposit Amount`" class="mb-0">
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16" v-for="i in questions.deposits" :key="i + 'laksdjf'" class="mb-2">
					<div v-if="step4.deposits[i - 1]">
						<a-col :span="2">
							<a-form-model-item :prop="`deposits.${i - 1}.date`" class="mb-0">
								<a-input disabled :value="i" />
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq === 'lg' ? 6 : 10">
							<a-form-model-item :prop="`deposits.${i - 1}.date`" class="mb-0">
								<a-date-picker placeholder="eg. 2022-12-31" v-model="step4.deposits[i - 1].date"
									style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
							</a-form-model-item>
						</a-col>
						<a-col :span="6">
							<a-form-model-item :prop="`deposits.${i - 1}.amount`" class="mb-0">
								<a-input-number v-if="$p < 40" class="input-number"
									:value="'$ ' +formatPrice(step4.deposits[i - 1].amount)" disabled/>
								<a-input-number v-else :min="0" @change="checkBalance"
									:formatter="value => '$ ' + formatPrice(value)"
									:parser="value => value.replace(/\$\s?|(,*)/g, '')"
									v-model="step4.deposits[i - 1].amount" style="width:100%" />
							</a-form-model-item>
						</a-col>
					</div>
				</a-row>
				<a-row>
					<a-col :span="6">
						<a-form-model-item :label="`Balance due on ${closingDateString}`" class="mt-2">
							<a-input-number class="w-full" :formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" disabled
								:value="step4.balanceDue" />
							<div slot="extra" class="text-danger" v-if="step4.balanceDue < 0">
								There might be an issue with the payment structure. Please review.
							</div>
							<div slot="extra" class="text-danger" v-else-if="depositClosingIssue">
								<span v-if="depositClosingIssue === 'before'">
									A Deposit date cannot be later than the closing date. Please review.
								</span>

							</div>
						</a-form-model-item>

					</a-col>
				</a-row>
			</a-card>

			<a-card title="Dynamic Purchaser" v-if="questions.choosePurchaser && transaction.purchasers.length > 1">
				<a-form-model-item class="mb-0" label="Choose a purchaser">
					<a-select v-model="step4.purchaserX">
						<a-select-option v-for="purchaser in transaction.purchasers" :value="purchaser.id"
							:key="purchaser.id">{{ purchaser.firstName }} {{ purchaser.lastName }}</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-card>

			<a-card title="Add Ons" class="mt-3"
				v-if="transaction.type == 'lease' && instance.productType === 'highrise' && addOnsList.length">
				<a-row :gutter="24">
					<a-col :span="$mq === 'lg' ? 8 : 24" v-for="(addOn, index) in addOnsList" :key="index">
						<a-row :gutter="16" v-if="$mq === 'lg'">
							<a-col :span="24">
								<h6>{{ addOn.name }}</h6>
								<a-divider />
							</a-col>
						</a-row>
						<div v-for="addOnIndex in [0, 1, 2]" :key="addOnIndex">
							<a-row :gutter="16" v-if="addOnsToShow[addOn.type] > addOnIndex"
								:class="(index || addOnIndex) && $mq !== 'lg' ? 'mt-3' : ''">
								<a-col :span="9">
									<p>{{ addOn.name }} #</p>
									<div v-if="!!addOns[addOn.type][addOnIndex].preDefined">
										<a-input :value="addOn.type + ' #' + addOns[addOn.type][addOnIndex].name"
											:disabled="!!addOns[addOn.type][addOnIndex].preDefined" />
									</div>
									<div v-else>
										<a-select :value="addOns[addOn.type][addOnIndex].id"
											@change="(e) => onAddOnChange(e, addOnIndex, addOn.type)" allowClear>
											<a-select-option v-for="(a, index) in generalAddOns[addOn.type]"
												:key="a.id + index" :value="a.id" :disabled="!!a.disabled">{{ addOn.name }}
												#{{ a.name
												}}</a-select-option>
										</a-select>
									</div>
								</a-col>
								<a-col :span="1" />
								<a-col :span="12">
									<p>{{ addOn.name }} Price($)</p>
									<a-input-number v-if="$p < 40" class="input-number"
										:value="'$ ' +formatPrice(addOns[addOn.type][addOnIndex].rent)" disabled/>
									<a-input-number v-else @change="calculateTotalAddOnsPrice"
										class="input-number"
										:formatter="value => '$ ' + formatPrice(value)"
										:parser="value => value.replace(/\$\s?|(,*)/g, '')"
										v-model="addOns[addOn.type][addOnIndex].rent" />
								</a-col>
							</a-row>
							<div v-if="addOnsToShow[addOn.type] > addOnIndex + 1 && $mq === 'lg'">
								<a-divider />
							</div>
						</div>
						<div v-if="index < 2 && $mq !== 'lg'">
							<a-divider />
						</div>
					</a-col>
				</a-row>

				<a-divider class="mt-5" />

				<a-row :gutter="16" class="mt-3">
					<a-col :span="$mq === 'lg' ? 3 : 10">
						<h6 class="mt-2">Add Ons MSRP </h6>
					</a-col>
					<a-col :span="$mq === 'lg' ? 4 : 10">
						<a-input-number v-if="$p < 40" class="input-number"
							:value="'$ ' +formatPrice(step4.addOnsRent)" disabled/>
						<a-input-number v-else class="input-number" @change="calculateTotalPurchase"
							v-model="step4.addOnsRent" :formatter="value => '$ ' + formatPrice(value)"
							:parser="value => value.replace(/\$\s?|(,*)/g, '')" required />
					</a-col>
				</a-row>
			</a-card>

			<a-card class="mt-3" :title="`Base Lease: $${transaction.product.unit.package.price.toLocaleString('us')}`"
				v-if="transaction.type == 'lease' && instance.productType === 'highrise'">
				<a-row :gutter="16">
					<a-col :span="6">
						<a-form-model-item label="Lease Deposit" prop="deposit"
							:rules="req('Please input the Lease Deposit')">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(step4.deposit)" disabled/>
							<a-input-number v-else class="input-number" v-model="step4.deposit"
								:formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item class="imp-label" label="Total Monthly Lease Rate (Offer)" prop="totalRent"
							:rules="req('Please input the Total Rent')">
							<a-input-number v-if="$p < 40" class="input-number"
								:value="'$ ' +formatPrice(step4.totalRent)" disabled/>
							<a-input-number v-else class="input-number" v-model="step4.totalRent"
								:formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-card>

			<a-card title="Custom Fields" v-if="customFields.length" class="mt-3">
				<a-form-model-item v-for="(item, index) in customFields" :help="item.help ? item.help : ''"
					:key="index + item.value" :label="item.name || 'Unnamed Question'"
					:val="`step4.customFields['${item.value}']`">
					<template v-if="item.type === 'text'">
						<a-input v-model="step4.customFields[item.value.replace('other.customFields.', '')]" />
					</template>
					<template v-else-if="item.type === 'checkmark'">
						<a-switch v-model="step4.customFields[item.value.replace('other.customFields.', '')]" />
					</template>
					<template v-else-if="item.type === 'multiple'">
						<a-select :value="step4.customFields[item.value.replace('other.customFields.', '')]"
							@change="(e) => selectMultiple(e, item.value.replace('other.customFields.', ''))">
							<a-select-option v-for="opt in item.configure.options" :key="opt.value"
								:value="opt.value">{{ opt.value }}</a-select-option>
						</a-select>
					</template>
					<template v-else-if="item.type === 'image'">
						<ImageBoxSelector v-model="step4.customFields[item.value.replace('other.customFields.', '')]" />
					</template>
					<template v-else-if="item.type === 'date'">
						<a-date-picker placeholder="Choose Date (eg. 2025-03-31)"
							v-model="step4.customFields[item.value.replace('other.customFields.', '')]"
							:disabled-date="disabledDate" style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
					</template>
					<template v-else-if="item.type === 'inclusion'">
						<a-select mode="multiple"
							v-model="step4.customFields[item.value.replace('other.customFields.', '')]">
							<a-select-option v-for="(inclusion, index) in inclusions" :key="inclusion.value + index"
								:value="inclusion.body" :label="inclusion.label">{{ inclusion.label }}</a-select-option>
						</a-select>
					</template>
				</a-form-model-item>
			</a-card>
		</a-form-model>
	</div>
</template>

<script>
import { generateContractPreview, isProductAvailable, getContractTemplateName } from './tools'
import { transformTransaction } from 'bh-mod/helper'
import moment from 'moment'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import { setProp, arrToObj } from 'bh-mod'

export default {
	components: { ImageBoxSelector },
	props: ['transaction'],
	data: () => ({
		finalTransaction: {},
		envelopeFileKey: null,
		anyQuestions: null,
		leaseQuestions: [],
		questions: {
			inclusions: false,
			deposits: 0,
			choosePurchaser: false
		},
		customFields: {},
		noAddOnsInCC: {
			parking: true,
			lockers: true,
			bikeRacks: true,
		},
		addOnsToShow: {
			parking: 0,
			lockers: 0,
			bikeRacks: 0
		},
		addOns: {
			parking: [{ id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }],
			lockers: [{ id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }],
			bikeRacks: [{ id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }, { id: '', price: 0, rent: 0 }],
		},
		step4: {
			premiums: [],
			lot: {},
			package: {
				parking: 0,
				locker: 0,
				lockerPrice: 0,
				parkingPrice: 0,
				bike: 0,
				bikePrice: 0,
			},
			balanceDue: 0,
			deposits: [],
			customFields: {},
			inclusions: [],
			purchaserX: '',
			deposit: 0,
			totalRent: 0,
			purchasePrice: 0,
			addOnsPrice: 0,
			addOnsRent: 0,
			reservationAmount: 0
		},
	}),
	computed: {
		currentElevation() {
			return this.$store.state.appData.currentElevation
		},
		addOnsList() {
			return [
				{ type: 'parking', name: 'Parking Spot' },
				{ type: 'lockers', name: 'Locker' },
				{ type: 'bikeRacks', name: 'Bike Rack' },
			].filter(addOn => !this.noAddOnsInCC[addOn.type] && this.addOnsToShow[addOn.type])
		},
		generalAddOns() {
			const addOns = this.condoAppSettings.addons || {}
			if (addOns.parking && addOns.parking.length) {
				addOns.parking = addOns.parking.filter(
					(p) => !p.unitId && p.status !== 'sold'
				);
			}

			if (addOns.lockers && addOns.lockers.length) {
				addOns.lockers = addOns.lockers.filter(
					(l) => !l.unitId && l.status !== 'sold'
				);
			}

			if (addOns.bikeRacks && addOns.bikeRacks.length) {
				addOns.bikeRacks = addOns.bikeRacks.filter(
					(b) => !b.unitId && b.status !== 'sold'
				);
			}
			return {
				parking: [...(addOns.parking || [])],
				lockers: [...(addOns.lockers || [])],
				bikeRacks: [...(addOns.bikeRacks || [])]
			}
		},

		unitAddOns() {
			const addOns = this.transaction.product.unit && this.transaction.product.unit.package && this.transaction.product.unit.package.other && this.transaction.product.unit.package.other.addons || {}
			return {
				parking: [...(addOns.parking || [])],
				lockers: [...(addOns.lockers || [])],
				bikeRacks: [...(addOns.bikeRacks || [])]
			}
		},

		unitPremiums() {
			let unitPremiums = this.transaction.product.unit && this.transaction.product.unit.package.other && this.transaction.product.unit.package.other.premiums || {}
			let price = 0
			Object.values(unitPremiums).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})
			return price
		},
		lotPremiums() {
			let lotP = 0
			if (this.transaction.product.lot.other && this.transaction.product.lot.other.premiums && Object.keys(this.transaction.product.lot.other.premiums).length) {
				Object.values(this.transaction.product.lot.other.premiums).forEach(item => {
					if (item.active) {
						lotP += item.price
					}
				})
			}

			return lotP
		},
		pkgPrice() {
			return this.transaction.product.unit.package.price || 0
		},
		msrp() {
			if (this.instance.productType === 'highrise') {
				return (this.pkgPrice + this.unitPremiums + this.step4.addOnsPrice - this.reservationAmount).toLocaleString('en-US')
			} else {
				return (this.pkgPrice + this.lotPremiums - this.reservationAmount).toLocaleString('en-US')
			}
		},

		instance() {
			return this.$store.state.instance
		},
		appSettings() {
			return this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options
		},
		condoAppSettings() {
			return (
				this.$store.state.appData.condoSettings &&
				this.$store.state.appData.condoSettings.options
			);
		},
		returningToStep() {
			return Object.keys(this.transaction.offer).length || Object.keys(this.transaction.other).length
		},
		depositClosingIssue() {
			if (!this.step4.deposits.length) return false
			let closing = moment(this.transaction.offer.closingDate).unix() * 1000

			for (const i in this.step4.deposits) {
				if (this.step4.deposits.hasOwnProperty(i)) {
					const dep = this.step4.deposits[i];
					if (!dep.date) return false
					let date = moment(dep.date).unix() * 1000
					if (date > closing) return 'before'
				}
			}
			return false

		},
		closingDateString() {
			let closing = this.transaction.offer.closingDate
			return moment(closing).format('MMM DD, YYYY')
		},
		defaultDeposits() {
			if (!this.$store.getters.appSettings) return []
			let dps = this.$store.getters.appSettings && this.$store.getters.appSettings.options && this.$store.getters.appSettings.options.deposits

			if (dps && dps.length > 1) {
				const id = this.currentContract && this.currentContract.options && this.currentContract.options.depositStructure || 'default';
				const dp = dps.find(d => d.id === id)
				return dp && dp.items || dps && dps[0] && dps[0].items || []
			} else {
				return dps && dps[0] && dps[0].items || []
			}
		},
		inclusions() {
			if (!this.$store.getters.appSettings) return []
			let incs = this.$store.getters.appSettings && this.$store.getters.appSettings.options && this.$store.getters.appSettings.options.inclusions
			let result = []
			if (incs) result = Object.values(incs)
			return result.map(inc => ({
				label: inc.title,
				value: inc.id,
				body: inc.body
			}))
		},
		currentContract() {
			if (!this.transaction.contract) return {}
			return this.$store.state.appData.templates[this.transaction.template] || {};
		},

		country() {
			let { country = "" } = this.instance;
			if (!country) country = "";
			country = country.toUpperCase().trim();
			const countryList = {
				CA: "Canada",
				US: "United States",
			}

			return countryList[country] || country;
		},

		address() {
			const obj = {
				municipal: '',
				municipal2: '',
				city: '',
				region: '',
				postal: '',
				postal2: '',
				legalDescription: '',
				country: this.country,
			}

			let productDetails = {}

			if (this.instance.productType === 'highrise') {
				productDetails = this.transaction.product.unit;
			} else {
				productDetails = this.transaction.product.lot;
			}

			obj.city = productDetails.city;
			obj.region = productDetails.region;
			obj.postal = productDetails.postal;
			obj.postal2 = productDetails.postal2 || '';
			obj.legalDescription = productDetails.legalDescription;

			if (productDetails.streetNumber) {
				obj.municipal += productDetails.streetNumber;
			}
			if (productDetails.street) {
				obj.municipal += ' ' + productDetails.street;
			}
			if (productDetails.city) {
				obj.municipal += ', ' + productDetails.city;
			}
			if (productDetails.region) {
				obj.municipal += ', ' + productDetails.region;
			}
			if (productDetails.postal) {
				obj.municipal += ', ' + productDetails.postal;
			}

			if (productDetails.streetNumber2) {
				obj.municipal2 += productDetails.streetNumber2;
			}
			if (productDetails.street2) {
				obj.municipal2 += ' ' + productDetails.street2;
			}
			if (productDetails.city) {
				obj.municipal2 += ', ' + productDetails.city;
			}
			if (productDetails.region) {
				obj.municipal2 += ', ' + productDetails.region;
			}
			if (productDetails.postal2) {
				obj.municipal2 += ', ' + productDetails.postal2;
			}

			return obj;
		},
		worksheet() {
			return this.$store.state.appData.worksheet
		},
		reservation() {
			return this.$store.state.appData.reservation
		},
		reservationAmount() {
			return (this.worksheet && this.worksheet.reservation && this.worksheet.reservation.amount) || (this.reservation && this.reservation.amount) || 0;
		}
	},
	watch: {
		'step4.purchasePrice'(val) {
			if (this.questions.deposits) {
				this.checkBalance()
			}
		},
	},
	methods: {
		disabledDate(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return date < today
		},
		calculateDeposits() {
			this.step4.deposits = []
			let dD = this.defaultDeposits
			let maxDeposit = this.questions.deposits
			let pPrice = this.step4.purchasePrice
			let balance = pPrice
			let lastDate = moment()

			for (let i = 0; i < maxDeposit; i++) {
				let amountToPay = 0
				let date = ''
				if (dD[i]) {
					date = moment(lastDate.toString())
					let { date: dateObj, amount } = dD[i]
					let { perc, value } = amount
					let { days, value: dateValue } = dateObj
					//Figure out Amount
					if (perc) {
						let percAmount = (value / 100) * pPrice
						// console.table({value,balance})
						if (percAmount <= balance) {
							amountToPay = percAmount
						} else {
							amountToPay = balance
						}
					} else if (value <= balance) {
						amountToPay = value
					}

					if (days) {
						date = date.add(dateValue, 'days')
					} else {
						date = moment(dateValue)
					}
				}

				balance = balance - amountToPay

				if (date) date = date.format('YYYY-MM-DD')
				this.step4.deposits.push({
					date,
					amount: amountToPay
				})
			}
			this.step4.balanceDue = balance
		},
		calcPrice() {
			let sofar = Number(this.step4.package.price || 0) + Number((this.transaction.type == 'purchase' ? this.step4.addOnsPrice : this.step4.addOnsRent) || 0)

			let premiums = Object.values(this.step4.premiums).reduce((acc, cur) => Number(acc || 0) + (cur.value || cur.price), 0)
			return sofar + premiums - this.reservationAmount
		},
		calculateTotalRent() {
			this.step4.totalRent = this.calcPrice()
		},
		calculateTotalPurchase() {
			this.step4.reservationAmount = this.reservationAmount;
			this.step4.purchasePrice = this.calcPrice()
		},
		calculateTotalAddOnsPrice() {
			if (this.transaction.type == 'purchase') {
				if (this.instance.productType === 'highrise') {
					this.step4.addOnsPrice = this.totalAddOnsPrice();
				}
				this.calculateTotalPurchase()
				this.calculateDeposits()
			} else {
				this.step4.addOnsRent = this.totalAddOnsRent();
				this.calculateTotalRent()
			}
		},
		totalAddOnsPrice() {
			let price = 0
			let Ids = []
			this.addOnsList.forEach((addOn) => {
				this.addOns[addOn.type].forEach(a => {
					if (a.id && a.price && !Ids.includes(a.id)) {
						Ids.push(a.id)
						price += a.price
					}
				})
			})
			return price
		},
		totalAddOnsRent() {
			let rent = 0;
			let Ids = []
			this.addOnsList.forEach((addOn) => {
				this.addOns[addOn.type].forEach(a => {
					if (a.id && a.rent && !Ids.includes(a.id)) {
						Ids.push(a.id)
						rent += a.rent
					}
				})
			})
			return rent
		},
		req: msg => ({ required: true, message: msg }),

		async createEnvelope() {
			console.log('Creating envelope...');
			this.$store.commit('LOAD', true)

			let transaction = this.finalTransaction

			transaction.envelope = {
				name: getContractTemplateName(transaction, this.instance.productType) || this.currentContract.name,
				isManual: true,
				file: {
					template: this.currentContract.id,
					key: this.envelopeFileKey
				}
			}

			if (transaction.type === 'lease') {
				transaction.price = this.step4.totalRent
			} else if (this.step4.purchasePrice) {
				transaction.price = this.step4.purchasePrice
			}

			let url = '/transactions/:instance'
			if (this.$route.params.id && transaction.id) {
				transaction.resend = true
				url = `/transactions/:instance/${transaction.id}`

				if (transaction.envelopes) {
					let mainEnvelope = transaction.envelopes.find(x => x.main)
					if (mainEnvelope) {
						transaction.envelope.id = mainEnvelope.id
						delete transaction.envelopes
					}
				}
			}

			this.$api[this.$route.params.id && transaction.id ? 'put' : 'post'](`${url}${this.$route.params.id && transaction.id ? '?unit=' + this.currentElevation : ''}`, transaction)
				.then(resp => {
					if (resp && resp.data && resp.data.id) {
						let tr = resp.data
						this.$store.commit('SET_TRANSACTION', { id: tr.id, value: tr })
						this.$router.push(`/transaction/${tr.id}?v=001`)
						this.$message.success('Transaction Created. Please remember to download and print')
					}
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while creating your transaction. Please try again.`))
					}
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.$store.commit('CLOSE_PREVIEW')

				})
		},
		async generatePreview() {
			if (this.$store.state.savingAsDraft) {
				this.$emit('done', { ...this.finalTransaction })
				return
			}
			const transaction = this.finalTransaction
			this.$store.commit('LOAD', true)
			if (!isProductAvailable(transaction, this)) {
				if (this.instance.productType == 'lowrise') {
					this.$message.error('Sorry this lot is no longer available. Please select another one.')
				} else {
					this.$message.error('Sorry this unit is no longer available. Please select another one.')
				}
				return
			}

			try {
				const { key, url } = await generateContractPreview(transaction, this)
				this.envelopeFileKey = key
				this.$store.commit('REVIEW_SEND', { preview: url, callback: this.createEnvelope, button: 'DOWNLOAD', isManual: true })
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
			}

			this.$store.commit('LOAD', false)

		},
		selectMultiple(e, i) {
			this.step4.customFields[i] = e
		},

		checkBalance() {
			let pPrice = this.step4.purchasePrice
			let amount = 0
			this.step4.deposits.forEach(dep => {
				amount += dep.amount
			})
			this.step4.balanceDue = pPrice - amount
		},
		formatPrice: (p) => {
			let isDot = false
			if (typeof p === 'string') {
				if (isNaN(+p[p.length - 1])) {
					if (p[p.length - 1] === '.') {
						isDot = true
					}
				}
				if (p[p.length - 4] === '.') {
					p = p.split('')
					p.pop()
					p = p.join('')
				}
				p = parseFloat(parseFloat(p).toFixed(2))
			}
			if (!p) return `0`
			let result = p.toLocaleString('en-us')
			if (isDot) result = result + '.'
			return result
		},
		validate() {
			if (this.depositClosingIssue || this.step4.balanceDue < 0) return this.$message.error('Please review the fields')

			let {
				deposit,
				balance = 0,
				totalRent = 0,
				deposits = [],
				purchasePrice,
				reservationAmount,
				addOnsPrice,
				addOnsRent,
				customFields = {},
				inclusions = [],
				package: pkg,
				premiums,
				purchaserX = '' } = this.step4

			pkg.other = pkg.other || {};
			pkg.other.premiums = pkg.other.premiums || {};

			premiums.forEach(prem => {
				pkg.other.premiums[prem.id] = prem
			})

			const finalAddOns = {}

			this.addOnsList.forEach((addOn) => {
				finalAddOns[addOn.type] = [];
				finalAddOns[addOn.type] = this.addOns[addOn.type].filter(a => a.id && a.price).map(a => {
					return {
						...a,
						status: 'hold',
						unitId: this.transaction.product.unit.id,
					}
				})
			})


			pkg.other.addons = finalAddOns;

			this.movedOn = true

			if (this.transaction.product.lot && this.transaction.product.lot && this.step4.premiums.length) {
				let finalPremiums = {}
				premiums.forEach(prem => {
					finalPremiums[prem.id] = prem
				})
				this.transaction.product.lot.other.premiums = finalPremiums
				this.transaction.offer.lotPremiums = this.lotPremiums
			}

			this.transaction.offer.basePrice = this.step4.package.price
			this.transaction.product.unit.package.price = this.step4.package.price

			if (this.transaction && this.transaction.type === 'purchase') {
				this.transaction.offer.purchasePrice = this.step4.purchasePrice
			}

			if (this.transaction.isManual) {
				let transaction = JSON.parse(JSON.stringify(this.transaction))

				if (this.$store.state.appData.allSettings && this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options) {
					transaction.other.defaults = this.$store.state.appData.allSettings.app.options
				}

				transaction.offer.date = Date.now()

				transformTransaction(transaction)
				if (this.step4.inclusions && this.step4.inclusions.length) {
					let incs = arrToObj(this.appSettings.inclusions)
					transaction.offer.inclusions = this.step4.inclusions.map(incId => {
						if (incs[incId]) {
							return incs[incId].body || ''
						}
						return ''
					})

				}

				let { package: pkg, deposits = [], totalRent, deposit, purchasePrice, addOnsPrice, addOnsRent, unitPrice, reservationAmount } = this.step4
				transaction.offer.basePrice = pkg.price
				transaction.offer.totalRent = totalRent
				transaction.offer.purchasePrice = purchasePrice
				transaction.offer.reservationAmount = reservationAmount
				transaction.offer.addOnsPrice = addOnsPrice
				transaction.offer.addOnsRent = addOnsRent
				transaction.product.unit.package.price = unitPrice
				transaction.offer.deposit = deposit
				transaction.offer.deposits = deposits
				if (typeof pkg === 'object' && this.transaction.product.unit && this.transaction.product.unit.packages) {
					transaction.product.unit.package = JSON.parse(JSON.stringify(pkg))
					transaction.product.unit.packages[0] = JSON.parse(JSON.stringify(pkg))
				}
				if (pkg && pkg.sqft2 && pkg.sqft2 > 0) {
					transaction.product.unit.package.sqft = `${pkg.sqft} plus ${pkg.sqft2}`
				}
				transaction.other.purchaserX = purchaserX
				transaction.other.customFields = customFields
				transaction.other.address = this.address


				if (transaction.type === 'purchase') {
					transaction.offer.balanceDue = this.step4.balanceDue
				}

				this.finalTransaction = transaction
				this.generatePreview()

			} else {
				let pkgCopy = JSON.parse(JSON.stringify(pkg))
				if (pkg && pkg.sqft2 && pkg.sqft2 > 0) {
					pkgCopy.sqft = `${pkg.sqft} plus ${pkg.sqft2}`
				}
				console.log('PACKAGE COPY', pkgCopy)

				let sendObj = {
					package: pkgCopy,

					offer: {
						deposit,
						totalRent,
						deposits,
						inclusions,
						balanceDue: this.step4.balanceDue,
						purchasePrice,
						addOnsPrice,
						addOnsRent,
						reservationAmount
					},
					other: {
						purchaserX,
						customFields,
						address: this.address
					}

				}
				this.$emit('done', sendObj)
			}
		},

		onAddOnChange(id, index, type = "parking", showError = false) {
			if (id) {
				const foundAddOn = this.generalAddOns[type].find(addOn => addOn.id === id);
				if (foundAddOn) {
					this.addOns[type][index] = { ...foundAddOn, rent: foundAddOn.price };
				} else if (showError) {
					this.$message.error(`${type} addon selected in the worksheet is not found or has been sold, so it won't process in the current transaction. Please confirm with agent.`)
				}
			} else {
				this.addOns[type][index] = {
					id: '',
					price: 0,
					rent: 0
				};
			}

			this.$forceUpdate()
			this.calculateTotalAddOnsPrice()
			if (this.transaction.type == 'purchase') {
				this.calculateDeposits()
			} else {
				this.calculateTotalRent()
			}
		}
	},
	mounted() {

		this.$emit('setFn', this.validate)

		if (this.unitAddOns) {
			this.unitAddOns.parking.forEach((p, index) => {
				this.addOns.parking[index] = { ...p, rent: p.price };
			})
			this.unitAddOns.lockers.forEach((l, index) => {
				this.addOns.lockers[index] = { ...l, rent: l.price };
			})
			this.unitAddOns.bikeRacks.forEach((b, index) => {
				this.addOns.bikeRacks[index] = { ...b, rent: b.price };
			})
			this.addOnsToShow.parking = this.unitAddOns.parking.length;
			this.addOnsToShow.lockers = this.unitAddOns.lockers.length;
			this.addOnsToShow.bikeRacks = this.unitAddOns.bikeRacks.length;
		}

		let cF = this.currentContract.customFields || {}
		let customFields = {}

		let any = false

		let { pages = [] } = this.currentContract

		let maxPurchasers = 0
		let maxDeposit = 0

		pages.forEach(page => {
			let { fields = [] } = page
			fields.forEach(spot => {
				let { field, text } = spot
				if (field.custom && cF[field.value] && !customFields[field.value]) {
					customFields[field.value] = cF[field.value]

					let fieldKey = field.value.replace('other.customFields.', '')
					if (field.type == 'multiple' && cF[field.value]?.configure?.defaultValue != '') {
						const value = ((this.returningToStep || this.$route.query.draft) && this.step4.customFields && this.step4.customFields[fieldKey]) || cF[field.value]?.configure?.options[parseInt(cF[field.value]?.configure?.defaultValue)]?.value
						setProp(this.step4.customFields, [fieldKey], value)
					} else {
						const value = ((this.returningToStep || this.$route.query.draft) && this.step4.customFields && this.step4.customFields[fieldKey]) || (cF[field.value]?.configure?.defaultValue)
						setProp(this.step4.customFields, [fieldKey], value)
					}
					any = true

				}

				if (field.deposit && text.deposits && text.deposits > this.questions.deposits) {
					this.questions.deposits = text.deposits
					maxDeposit = text.deposits
					any = true
				}

				if (field.inclusions && !this.questions.inclusions) {
					this.questions.inclusions = true
					any = true
				}

				if (field.value.includes('purchaserX') && !this.questions.choosePurchaser) {
					this.questions.choosePurchaser = true
					any = true
				}
				if (field.value.includes('purchasers[]') && text.purchasers > maxPurchasers) {
					maxPurchasers = text.purchasers
				}

				if (field.name.startsWith('#1 Parking Spot') && this.addOnsToShow.parking <= 1) {
					this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 1
				}
				if (field.name.startsWith('#2 Parking Spot') && this.addOnsToShow.parking <= 2) {
					this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 2
				}
				if (field.name.startsWith('#3 Parking Spot') && this.addOnsToShow.parking <= 3) {
					this.noAddOnsInCC.parking = false;
					this.addOnsToShow.parking = 3
				}

				if (field.name.startsWith('#1 Locker') && this.unitAddOns.lockers.length <= 1) {
					this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 1
				}
				if (field.name.startsWith('#2 Locker') && this.unitAddOns.lockers.length <= 2) {
					this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 2
				}
				if (field.name.startsWith('#3 Locker') && this.unitAddOns.lockers.length <= 3) {
					this.noAddOnsInCC.lockers = false;
					this.addOnsToShow.lockers = 3
				}

				if (field.name.startsWith('#1 Bike Rack') && this.unitAddOns.bikeRacks.length <= 1) {
					this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 1
				}
				if (field.name.startsWith('#2 Bike Rack') && this.unitAddOns.bikeRacks.length <= 2) {
					this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 2
				}
				if (field.name.startsWith('#3 Bike Rack') && this.unitAddOns.bikeRacks.length <= 3) {
					this.noAddOnsInCC.bikeRacks = false;
					this.addOnsToShow.bikeRacks = 3
				}
			})
		})

		this.anyQuestions = any
		this.customFields = Object.values(customFields)
		this.transaction.other.purchasersInContract = maxPurchasers

		this.step4.package = JSON.parse(JSON.stringify(this.transaction.product.unit.package))

		this.addOnsToShow.parking = Math.min(this.addOnsToShow.parking, this.step4.package.maxParking || 0)
		this.addOnsToShow.lockers = Math.min(this.addOnsToShow.lockers, this.step4.package.maxLocker || 0)
		this.addOnsToShow.bikeRacks = Math.min(this.addOnsToShow.bikeRacks, this.step4.package.maxBikeRack || 0)

		if (!this.step4.package.parkingNote) this.step4.package.parkingNote = ''
		if (!this.step4.package.bikeNote) this.step4.package.bikeNote = ''
		if (!this.step4.package.lockerNote) this.step4.package.lockerNote = ''

		if (this.instance.productType === 'highrise') {

			this.step4.premiums = JSON.parse(JSON.stringify(Object.values(this.transaction.product.unit.package.other.premiums)))

		} else if (this.instance.productType === 'lowrise') {

			if (this.transaction.product.lot.other && this.transaction.product.lot.other.premiums) {
				let thePrems = JSON.parse(JSON.stringify(this.transaction.product.lot.other.premiums))
				let allPrems = []
				Object.entries(thePrems).forEach(([key, prem]) => {
					if (prem.active) {
						prem.id = key
						allPrems.push(prem)
					}
				})
				this.step4.premiums = allPrems
			}
		}
	},
	created() {
		if (this.returningToStep || this.$route.query.draft) {
			let {
				offer = {
					deposits: [],
					inclusions: [],
					balanceDue: 0
				}, other = {
					purchaserX: "",
					customFields: {}
				}
			} = this.transaction

			let { deposits, inclusions, balanceDue } = offer
			let { customFields, purchaserX } = other

			this.step4.package = JSON.parse(JSON.stringify(this.transaction.product.unit.package))

			if (this.transaction.product.lot) {
				let thePrems = JSON.parse(JSON.stringify(this.transaction.product.lot.other.premiums))
				let allPrems = []
				Object.entries(thePrems).forEach(([key, prem]) => {
					if (prem.active) {
						prem.id = key
						allPrems.push(prem)
					}
				})
				this.step4.premiums = allPrems
			}

			if (!this.step4.package.bike) this.step4.package.bike = 0
			if (!this.step4.package.bikePrice) this.step4.package.bikePrice = 0

			this.step4 = {
				...this.step4,
				balanceDue: balanceDue || this.step4.balanceDue,
				deposits: deposits || this.step4.deposits,
				customFields: customFields || this.step4.customFields,
				inclusions: inclusions || this.step4.inclusions,
				purchaserX: purchaserX || this.step4.purchaserX
			}

			if (this.step4.inclusions && this.step4.inclusions.length) {
				this.step4.inclusions = this.step4.inclusions.map(inc => {
					const found = this.inclusions.find(i => i.value == inc || i.body == inc)
					if (found) {
						return found.value
					}
					return null
				}).filter(i => i)
			}
		}

		// Set Add on value if transaction is creating from submitted worksheet
		if (this.worksheet && this.worksheet.data && this.worksheet.data.addons && Object.keys(this.worksheet.data.addons).length) {
			// Adding timeout to populate all the values correctly
			setTimeout(() => {
				this.addOnsList.forEach((addOn) => {
					const currentAddOn = this.worksheet.data.addons[addOn.type];
					if (currentAddOn) {
						const maxAddOn = this.addOnsToShow[addOn.type];
						currentAddOn.forEach((addOnId, index) => {
							if (maxAddOn > index) {
								this.onAddOnChange(addOnId, index, addOn.type, true)
							}
						})
					}
				})
			}, 500)
		}

		setTimeout(() => {
			if (this.transaction.type == 'purchase') {
				this.calculateTotalAddOnsPrice()
				this.calculateTotalPurchase()
				this.calculateDeposits()
			} else {
				this.calculateTotalRent()
			}

			if (this.returningToStep || this.$route.query.draft) {
				let {
					offer = {
						deposit: 0,
						totalRent: 0,
						purchasePrice: 0,
						addOnsPrice: 0,
						addOnsRent: 0,
						reservationAmount: 0,
					}
				} = this.transaction
				let { deposit, deposits, totalRent, purchasePrice, addOnsPrice, addOnsRent, reservationAmount } = offer
				this.step4 = {
					...this.step4,
					deposit: deposit || this.step4.deposit,
					deposits: deposits || this.step4.deposits,
					totalRent: totalRent || this.step4.totalRent,
					purchasePrice: purchasePrice || this.step4.purchasePrice,
					addOnsPrice: addOnsPrice || this.step4.addOnsPrice,
					addOnsRent: addOnsRent || this.step4.addOnsRent,
					reservationAmount: reservationAmount || this.step4.reservationAmount,
				}
			}

		}, 1000);
	}
}
</script>

<style lang="scss">
.step4 .input-number {
	width:300px !important;
	max-width:100% !important
}
</style>
